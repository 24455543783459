function createMixin(){
	return {
		/**
		 * Get top coordinate by row index (order)
		 * @param {number} index 
		 */
		getRowTop: function(index){
			return index * this.$getConfig().row_height;
		},

		/**
		 * Get top coordinate by item id
		 * @param {*} task_id 
		 */
		getItemTop: function (task_id) {
			if(this.$config.rowStore){
				var store = this.$config.rowStore;
				if(!store) return 0;

				if(store.getParent && store.exists(task_id) && store.exists(store.getParent(task_id))){
					var parent = store.getItem(store.getParent(task_id));
					if(this.$gantt.isSplitTask(parent)){
						return this.getRowTop(store.getIndexById(parent.id));
					}
				}
				return this.getRowTop(store.getIndexById(task_id));

			}else{
				return 0;
			}

		}
	};
}

module.exports = createMixin;